img {
  max-width: 100%;
}

.selection-prohibited {
  user-select: none;
}

.svg {
  svg {
    width: 100%;
    height: 100%;
  }
}
